<template>
    <div class="modal show">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="$emit('close')" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">{{title}}</h4>
                </div>
                <div class="modal-body">
                    <form  class="form-horizontal">
                        <div class="form-group"  v-bind:class="[errName ? 'has-error' : '']">
                            <label class="col-sm-2 control-label">Название*</label>
                            <div class="col-sm-10">
                                <input type="text" v-model="view.name" class="form-control input-sm">
                                    <span class="help-block" v-show="errName">{{errName}}</span>
                            </div>
                        </div>
                        <div class="form-group"  v-bind:class="[errTpl ? 'has-error' : '']">
                            <label class="col-sm-2 control-label">Шаблон*</label>
                            <div class="col-sm-10">
                                <textarea v-model="view.tpl" class="form-control input-sm"></textarea>
                                    <span class="help-block" v-show="errTpl">{{errTpl}}</span>
                                    <p class="help-block">
                                        Вы можете использовать следующие теги<br>
                                            Для комментариев:<br>
                                                <ul>
                                                    <li>[КОММЕНТАРИЙ-АВТОР]</li>
                                                    <li>[КОММЕНТАРИЙ]</li>
                                                </ul>
                                            Для заказов:<br>
                                                <ul>
                                                    <li>[ЗАКАЗ-НОМЕР]</li>
                                                    <li>[КЛИЕНТ-ИМЯ]</li>
                                                    <li>[КЛИЕНТ-ТЕЛЕФОН]</li>
                                                    <li>[ДАТА-ВРЕМЯ-ИСПОЛНЕНИЯ-ЗАКАЗА]</li>
                                                    <li>[СТАТУС]</li>
                                                    <li>[МАСТЕРСКАЯ]</li>
                                                    <li>[К-ОПЛАТЕ]</li>
                                                </ul>
                                    </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button class="btn pull-left btn-link" v-show="btnDel" @click="del" > Удалить</button>
                    <button class="btn btn-default" @click="$emit('close')" > Закрыть</button>
                    <button class="btn btn-primary" @click="save"> Coхранить</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'view'],
    data(){
        return{
            errName:false,
            errTpl:false,
            btnDel:false,
        }
    },
    computed: {

    },
    mounted:function(){

        this.btnDel=this.view.id?true:false;
    },
    methods: {
        del: function(){
            this.$emit('delete',this.view);
        },
        save: function() {
            var t=this,flg_check=true;
            var arr =Object.assign({}, this.view);
            if (!this.view.name){
                this.errName='Поле должно быть заполнено';
                flg_check=false;
            }else{
                this.errName=false;
            }
            if (!this.view.tpl){
                this.errTpl='Поле должно быть заполнено';
                flg_check=false;
            }else{
                this.errTpl=false;
            }

            if (flg_check){
            this.$http.post(this.$store.state.apiUrl+'notification/savetpl/',{
                data: arr
            })
            .then(
                (response) => {
                    t.$store.dispatch('setMessage',{
                        'type':response.body.err==1?'error':'success',
                        'message':response.body.msg
                    })
                    if (!response.body.err){
                        this.$emit('close','reload');
                    }
                }, 
                (err) => { 
                    console.log(err)
                })
            }
        }
    }
}
</script>

<style>

</style>
