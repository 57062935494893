<template>
  <div>
    <h3>Оповещения</h3>
    <tabletpl
      v-bind:columns="columns"
      v-bind:table_data="tableData"
      v-bind:table_load="tableLoad"
      v-on:add="add"
      v-on:edit="edit"
    >
    </tabletpl>

    <table class="table">
      <colgroup>
        <col width="40%" />
        <col width="30%" />
        <col width="30%" />
      </colgroup>
      <thead>
        <tr>
          <th>Тип события</th>
          <th>Отправлять SMS</th>
          <th>Отправлять Email</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="3" class="info">Для клиента</td>
        </tr>
        <tr>
          <td>Заказ создан</td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(1, 1)"
                  v-model="NfData.t1r1"
                />
                <select
                  @change="saveNf(1, 1)"
                  v-model="NfData.t1r1"
                  v-if="NfData.t1r1"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(2, 1)"
                  v-model="NfData.t1r2"
                />
                <select
                  @change="saveNf(2, 1)"
                  v-model="NfData.t1r2"
                  v-if="NfData.t1r2"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Заказ готов</td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(1, 2)"
                  v-model="NfData.t2r1"
                />
                <select
                  @change="saveNf(1, 2)"
                  v-model="NfData.t2r1"
                  v-if="NfData.t2r1"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:value="td.id"
                    v-bind:key="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(2, 2)"
                  v-model="NfData.t2r2"
                />
                <select
                  @change="saveNf(2, 2)"
                  v-model="NfData.t2r2"
                  v-if="NfData.t2r2"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:value="td.id"
                    v-bind:key="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Заказ закрыт</td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(1, 3)"
                  v-model="NfData.t3r1"
                />
                <select
                  @change="saveNf(1, 3)"
                  v-model="NfData.t3r1"
                  v-if="NfData.t3r1"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:value="td.id"
                    v-bind:key="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(2, 3)"
                  v-model="NfData.t3r2"
                />
                <select
                  @change="saveNf(2, 3)"
                  v-model="NfData.t3r2"
                  v-if="NfData.t3r2"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Изменение статуса заказа</td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(1, 4)"
                  v-model="NfData.t4r1"
                />
                <select
                  @change="saveNf(1, 4)"
                  v-model="NfData.t4r1"
                  v-if="NfData.t4r1"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(2, 4)"
                  v-model="NfData.t4r2"
                />
                <select
                  @change="saveNf(2, 4)"
                  v-model="NfData.t4r2"
                  v-if="NfData.t4r2"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="info">Для исполнитетя</td>
        </tr>
        <tr>
          <td>Добавлен комментарий в заказ</td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(1, 5)"
                  v-model="NfData.t5r1"
                />
                <select
                  @change="saveNf(1, 5)"
                  v-model="NfData.t5r1"
                  v-if="NfData.t5r1"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(2, 5)"
                  v-model="NfData.t5r2"
                />
                <select
                  @change="saveNf(2, 5)"
                  v-model="NfData.t5r2"
                  v-if="NfData.t5r2"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Назначен исполнитель</td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(1, 6)"
                  v-model="NfData.t6r1"
                />
                <select
                  @change="saveNf(1, 6)"
                  v-model="NfData.t6r1"
                  v-if="NfData.t6r1"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(2, 6)"
                  v-model="NfData.t6r2"
                />
                <select
                  @change="saveNf(2, 6)"
                  v-model="NfData.t6r2"
                  v-if="NfData.t6r2"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Изменение статуса заказа</td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(1, 7)"
                  v-model="NfData.t7r1"
                />
                <select
                  @change="saveNf(1, 7)"
                  v-model="NfData.t7r1"
                  v-if="NfData.t7r1"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
          <td>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  @click="saveNf(2, 7)"
                  v-model="NfData.t7r2"
                />
                <select
                  @change="saveNf(2, 7)"
                  v-model="NfData.t7r2"
                  v-if="NfData.t7r2"
                  class="form-control input-sm"
                >
                  <option
                    v-for="td in tableData"
                    v-bind:key="td.id"
                    v-bind:value="td.id"
                  >
                    {{ td.name }}
                  </option>
                </select>
              </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <modal_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteGroup"
    ></modal_edit>
    <modal_delete
      v-if="modal_delete"
      v-bind:view="modalView"
      v-on:close="modalClose"
    ></modal_delete>
  </div>
</template>

<script>
import tabletpl from '../../components/table.vue'
import modal_edit from './tpl_edit.vue'
import modal_delete from './tpl_delete.vue'

export default {
  data() {
    return {
      columns: [
        { name: 'name', title: 'Имя' },
        { name: 'tpl', title: 'Шаблон' },
      ],
      tableLoad: false,
      tableData: ['load'],
      modal: '',
      modal_delete: '',
      modalTitle: '',
      modalView: '',
      flgSms: [],
      flgEmail: [],
      NfData: [],
    }
  },
  components: {
    tabletpl,
    modal_edit,
    modal_delete,
  },
  mounted: function () {
    this.dataLoad()
  },
  methods: {
    modalClose: function (prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    dataLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'notification').then(
        (response) => {
          if (!response.body.err) {
            t.NfData = response.body
            //                        console.log(t.NfData);
          }
        },
        (err) => {
          console.log(err)
        }
      )

      this.$http.post(this.$store.state.apiUrl + 'notification/tpl').then(
        (response) => {
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
            //                        console.log(t.tableData);
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    add: function () {
      this.modal = true
      this.modalTitle = 'Добавление шаблона'
      this.modalView = []
    },
    edit: function (view) {
      this.modal = true
      this.modalTitle = 'Редактирование шаблона'
      this.modalView = view
    },
    deleteGroup: function (view) {
      //            console.log(view)
      this.modal_delete = true
      this.modalView = view
    },
    saveNf: function (route, tpe) {
      var t = this
      //            console.log(this.NfData)
      //            console.log(this.tableData[0].id)
      if (this.NfData['t' + tpe + 'r' + route] == true) {
        this.NfData['t' + tpe + 'r' + route] = this.tableData[0].id
      }
      this.$http
        .post(this.$store.state.apiUrl + 'notification/saveNf/', {
          tpe: tpe,
          route: route,
          tpl: this.NfData['t' + tpe + 'r' + route],
        })
        .then(
          (response) => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
          },
          (err) => {
            console.log(err)
          }
        )
    },
  },
}
</script>

<style>
.checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
