<template>
    <div class="modal show">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="$emit('close')" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">Удаление шаблона</h4>
                </div>
                <div class="modal-body">
                    Вы на самом деле хотите удалить шаблон <b>{{view.name}}</b>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-default" @click="$emit('close')" > Закрыть</button>
                    <button class="btn btn-danger" @click="save"> Удалить</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['view'],
    data(){
        return{

        }
    },
    computed: {

    },
    mounted:function(){
//        console.log(this.view)
    },
    methods: {
        save: function() {
            var t=this;
            this.$http.post(this.$store.state.apiUrl+'notification/deletetpl/',{
                id: this.view.id
            })
            .then(
                (response) => {
                    t.$store.dispatch('setMessage',{
                        'type':response.body.err==1?'error':'success',
                        'message':response.body.msg
                    })
                    if (!response.body.err){
                        this.$emit('close','reload');
                    }
                }, 
                (err) => { 
                    console.log(err)
                })          
        }
    }
}
</script>

<style>

</style>
