<script>
export default {
  props: ['columns', 'table_data', 'table_load', 'readonly'],
  methods: {
    tbody_tr_click: function (view) {
      this.$emit('edit', view)
    },
    add: function () {
      this.$emit('add')
    },
  },
  render: function (createElement) {
    var columns = this.columns,
      table_data = this.table_data,
      readonly = this.readonly,
      t = this
    function renderTbody() {
      if (table_data[0] == 'load') {
        return createElement(
          'tbody',
          table_data.map(function () {
            return createElement('tr', [
              createElement(
                'td',
                { attrs: { colspan: 3 } },
                'Загрузка данных...'
              ),
            ])
          })
        )
      } else if (table_data[0] == 'error') {
        return createElement(
          'tbody',
          table_data.map(function () {
            return createElement('tr', [
              createElement('td', { attrs: { colspan: 3 } }, 'Ошибка загрузки'),
            ])
          })
        )
      } else {
        return createElement(
          'tbody',
          table_data.map(function (item) {
            return createElement(
              'tr',
              {
                on: {
                  click: function () {
                    t.tbody_tr_click(item)
                  },
                },
              },
              columns.map(function (citem) {
                return createElement('td', item[citem.name])
              })
            )
          })
        )
      }
    }
    function buttonAdd() {
      //                console.log(readonly);
      if (readonly) {
        return ''
      }
      return createElement(
        'button',
        {
          class: { 'btn btn-success pull-right': true },
          on: {
            click: t.add,
          },
        },
        'Добавить'
      )
    }
    if (this.columns.length) {
      return createElement('div', [
        buttonAdd(),
        createElement(
          'table',
          {
            class: { 'table table-striped  table-hover table-condensed': true },
            style: { cursor: 'pointer' },
            /*on: {
                         click: this.hello
                         }*/
          },
          [
            createElement(
              'colgroup',
              columns.map(function (item) {
                return createElement('col', {
                  attrs: { width: item.width == undefined ? '*' : item.width },
                })
              })
            ),
            createElement('thead', [
              createElement(
                'tr',
                columns.map(function (item) {
                  return createElement('th', item.title)
                })
              ),
            ]),
            renderTbody(),
          ]
        ),
      ])
    } else {
      return createElement('p', 'Ничего не найдено.')
    }
  },
}
</script>

<style></style>
